<template>
  <div>
    <div class="order">
      <div>{{ offset + idx + 1 }}</div>
    </div>
    <div class="col">
      <div class="columns">
        <div class="column is-1 text-align">
          {{ ConvertToDateTime.ConvertDateToStringDatetime(data.CreatedDate) }}
        </div>
        <div class="column is-1">
          {{ data.ReferenceLoanId }}
        </div>
        <div class="column is-2 custom-w-12">
          {{ data.ReferenceCustomerCode }}
        </div>
        <div class="column is-1">
          {{ data.BusinessType }}
        </div>
        <div class="column is-1">
          {{ data.TrantractionName }}
        </div>
        <div class="column is-2 break-word">
          {{ data.TxnNote }}
        </div>
        <div class="column is-1 money-in text-align">
          <p v-if="(data.TxnType.Id != 101 && data.TxnType.Id != 103) || data.TxnType.Id ==105">0</p>
          <p v-else >+ {{ formatCurrency(data.TxnAmount) }}</p>
        </div>
        <div class="column is-1 money-out text-align">
          <p v-if="(data.TxnType.Id != 104 && data.TxnType.Id != 102) || data.TxnType.Id ==105">0</p>
          <p v-else>- {{ formatCurrency(data.TxnAmount) }}</p>
        </div>
        <div class="column is-2 break-word">
          {{ data.ReferenceReceiptCode }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConvertToDateTime from "@/helpers/ConvertDatetime.vue";
export default {
  props: ["data", "idx", "offset"],
  mounted() {
  },
  data() {
    return {
      ConvertToDateTime: ConvertToDateTime,
    };
  },
};
</script>
<style scoped>
.money-in{
    color: #2CB66B;
    font-weight: 500;
}
.money-out{
    color: #FF342A;
    font-weight: 500;
}
.break-word{
  word-wrap: break-word;
}
</style>
