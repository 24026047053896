<script>
import Repository from "@/repositories/Repository.vue";
import Enum from "@/helpers/Enum.vue";
const Get_ListMethodCashBook = async () => {
  return await Repository.get(Enum.API.Category.ListMethodCashBook);
};
const ListCashBook= async(item)=>{
  return await Repository.post(Enum.API.Mifos.ListCashBook,item);
}
const ExportExcelCashBook=async(item,Name,ShopName)=>{
return await Repository.postDownload(Enum.API.CashBook.ExportExcel+`?Name=${Name}&ShopName=${ShopName}`,item);
}
export default {
Get_ListMethodCashBook,
ListCashBook,
ExportExcelCashBook
};
</script>