<template>
  <div class="box-child column">
    <div class="col-1">
      <div class="item-box">
        <p class="label-item">
          Quỹ {{text}} đầu kỳ:
          <b class="money-box">{{ formatCurrency(BalanceBegin) }}</b>
        </p>
      </div>
      <div class="item-box">
        <p class="label-item-2">
          Quỹ {{text}} cuối kỳ:<b class="money-box">{{
            formatCurrency(BalanceEnd)
          }}</b>
        </p>
      </div>
    </div>
    <div class="col-2">
      <div class="item-box-2">
        <p class="label-item-col-2">GIAO DỊCH HỢP ĐỒNG</p>
        <b class="money-col-2" v-if="sumData.CC >= 0"
          >+ {{ formatCurrency(sumData.CC) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.CC) }}</b>
      </div>
      <div class="item-box-2">
        <p class="label-item-col-2">BẢO HIỂM</p>
        <b class="money-col-2" v-if="sumData.BH >= 0"
          >+ {{ formatCurrency(sumData.BH) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.BH) }}</b>
      </div>
      <div class="item-box-2">
        <p class="label-item-col-2">BÁN HÀNG THANH LÝ</p>
        <b class="money-col-2" v-if="sumData.TL >= 0"
          >+ {{ formatCurrency(sumData.TL) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.TL) }}</b>
      </div>
      <div class="item-box-2">
        <p class="label-item-col-2">THU CHI HĐ</p>
        <b class="money-col-2" v-if="sumData.TC >= 0"
          >+ {{ formatCurrency(sumData.TC) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.TC) }}</b>
      </div>
      <div class="item-box-2">
        <p class="label-item-col-2">CHUYỂN QUỸ NỘI BỘ</p>
        <b class="money-col-2" v-if="sumData.CQ >= 0"
          >+ {{ formatCurrency(sumData.CQ) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.CQ) }}</b>
      </div>
      <div class="item-box-2">
        <p class="label-item-col-2">DỊCH VỤ ADD-ON</p>
        <b class="money-col-2" v-if="sumData.AD >= 0"
          >+ {{ formatCurrency(sumData.AD) }}</b
        >
        <b class="money-col-2 red" v-else> {{ formatCurrency(sumData.AD) }}</b>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data", "category", "isactive","text"],
  mounted() {
    let _self = this;
    if (!this.isactive) {
      _self.BalanceBegin = 0;
      _self.BalanceEnd = 0;
      _self.SetDefaultData();
    }
  },
  data() {
    return {
      BalanceBegin: 0,
      BalanceEnd: 0,
      sumData: {
        CC: 0,
        BH: 0,
        TL: 0,
        TC: 0,
        CQ: 0,
        AD: 0,
      },
    };
  },
  methods: {
    SetDefaultData() {
      let _self = this;
      _self.sumData.CC = 0;
      _self.sumData.BH = 0;
      _self.sumData.TL = 0;
      _self.sumData.TC = 0;
      _self.sumData.CQ = 0;
      _self.sumData.AD = 0;
    },
  },
  watch: {
    data: function (val) {
      let _self = this;
      if (val != null && this.isactive) {
        _self.SetDefaultData();
        _self.BalanceBegin = val.MoneyBeginBalance;
        _self.BalanceEnd = val.MoneyEndBalance;
        val.Transactions.PageItems.forEach((element) => {
          if (element.BusinessType == "CC") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.CC += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.CC = _self.sumData.CC - element.TxnAmount;
            }
          }
          if (element.BusinessType == "BH") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.BH += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.BH = _self.sumData.BH - element.TxnAmount;
            }
          }
          if (element.BusinessType == "TL") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.TL += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.TL = _self.sumData.TL - element.TxnAmount;
            }
          }
          if (element.BusinessType == "TC") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.TC += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.TC = _self.sumData.TC - element.TxnAmount;
            }
          }
          if (element.BusinessType == "CQ") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.CQ += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.CQ = _self.sumData.CQ - element.TxnAmount;
            }
          }
          if (element.BusinessType == "AO") {
            if (element.TxnType.Id == 101 || element.TxnType.Id == 103) {
              _self.sumData.AD += element.TxnAmount;
            } else if (element.TxnType.Id == 102 || element.TxnType.Id == 104) {
              _self.sumData.AD = _self.sumData.AD - element.TxnAmount;
            }
          }
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
