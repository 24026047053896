<template>
  <div class="lst-cashbook">
    <ul v-for="(item, idx) in category" :key="item.Code">
      <li
        class="item-filter-0"
        v-if="idx == 0"
        :class="{ isactive: idx === activeItem }"
        @click="Filter(item.Code, idx)"
      >
        {{ item.Name }}
      </li>
      <li
        class="item-filter"
        @click="Filter(item.Code, idx)"
        :class="{ isactive: idx === activeItem }"
        v-else
      >
        {{ item.Name }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ["category"],
  data() {
    return {
      isActive: false,
      activeItem: null,
      selected: null,
    };
  },
  methods: {
    Filter(val, index) {
      let _self = this;
      if (_self.activeItem == null || _self.activeItem != index) {
        _self.activeItem = index;
      } else if (_self.activeItem == index) {
        _self.activeItem = null;
        val=null;
      }
      _self.$emit("FilterByCash", val);
    },
  },
};
</script>
<style scoped>
.isactive {
  color: #007aff !important;
  font-weight: bold;
}
</style>
